/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@font-face {
  font-family: "micr-e13b";
  src: url("/fonts/micr-e13b.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Camphor";
  src: url("/fonts/camphor-ss/400-regular.woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Camphor";
  src: url("/fonts/camphor-ss/300-light.woff2");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Camphor";
  src: url("/fonts/camphor-ss/500-medium.woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Camphor";
  src: url("/fonts/camphor-ss/600-bold.woff2");
  font-style: normal;
  font-weight: 600;
}

html {
  line-height: 1.3;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  color: rgba(26, 26, 26, 0.9);
  -webkit-font-smoothing: antialiased;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

@tailwind utilities;

@layer utilities {
  @responsive {
    .backface-visibility-hidden {
      backface-visibility: hidden;
    }
    .blend-soft-light {
      mix-blend-mode: soft-light;
    }
    .flex-basis-0 {
      flex-basis: 0;
    }
    .flex-basis-auto {
      flex-basis: auto;
    }
    .flex-basis-full {
      flex-basis: 100%;
    }
    .transitions-input {
      transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s,
        -webkit-filter 50000s;
    }
    .transitions-pay-button {
      transition: all 0.2s ease, box-shadow 0.08s ease-in;
    }
    .perspective-800 {
      -webkit-perspective: 800px;
      perspective: 800px;
    }
    .translate-z-0 {
      transform: translateZ(0);
    }
  }
}
